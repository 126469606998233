import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';
import { setJwt } from './util';

// import { DdSdkReactNative, DdSdkReactNativeConfiguration } from '@datadog/mobile-react-native';
// const config = new DdSdkReactNativeConfiguration(
//   "pubb798c2f3fd8c1255f501b916b190d1bb",
//   "<ENV_NAME>",
//   "86ffdb4a-11ae-4f60-9bb1-ace0c758d4dc",
//   true, // track User interactions (e.g.: Tap on buttons. You can use 'accessibilityLabel' element property to give tap action the name, otherwise element type will be reported)
//   true, // track XHR Resources
//   true // track Errors
// );
// // Optional: Select your Datadog website (one of "US", "EU" or "GOV")
// config.site = "US1"
// // Optional: enable or disable native crash reports
// config.nativeCrashReportEnabled = true 
// // Optional: sample RUM sessions (here, 80% of session will be sent to Datadog. Default = 100%)
// config.sampleRate = 80
// await DdSdkReactNative.initialize(config)

import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://f7f7260059b0c56d32f6403db4ece984@o4506086643466240.ingest.sentry.io/4506086645694464",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/monitor\.softensify.com\//],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import React from 'react'
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

// Bugsnag.start({
//   apiKey: '223369fab8402cb6c75519e9b3590dd8',
//   plugins: [new BugsnagPluginReact()]
// });

function getQueryVariable(variable: string, search = window.location.search): string | undefined {
  const query = search.substring(1);
  const vars = query.split('&');
  for (const v of vars) {
    const pair = v.split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
}

const jwt = getQueryVariable('jwt');

if (jwt === undefined) {
  history.replaceState(null, document.title, location.pathname + "#!/tempURL"); 
  history.pushState(null, document.title, location.pathname);
  window.addEventListener("popstate", function() {
    if(location.hash === "#!/tempURL") {
      history.replaceState(null, document.title, location.pathname);
      setTimeout(function(){
        location.replace('/');
      },0);
    }
  }, false);
  // const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

  const root = ReactDOM.createRoot(document.getElementById('root')!);
  root.render(<App />);
  // Bugsnag.notify(new Error('Test error'));
} else {
  setJwt(jwt);
  location.assign(location.origin);
}
