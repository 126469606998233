import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Watch, WatchCategory, WatchSku } from '../../types';
import Button from 'react-bootstrap/Button';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

export function WatchRecord({ watch, deleteHandler }: { watch: Watch, deleteHandler: (watchId: string) => void }) {
  return watch.type === 'category'
    ? WatchCategoryRecord({ watch, deleteHandler })
    : WatchSkuRecord({ watch, deleteHandler });
}

function WatchCategoryRecord({ watch, deleteHandler }: { watch: WatchCategory, deleteHandler: (watchId: string) => void }) {
  function del(): void {
    deleteHandler(watch.watchId);
  }

  return (
    <Row style={{marginBottom: '5px'}}>
      <Col>Category: {watch.category}</Col>
      <Col style={{width: '100%'}}>
        <Button style={{float: 'right'}} onClick={del} variant="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button>
      </Col>
    </Row>
  );
}

function WatchSkuRecord({ watch, deleteHandler }: { watch: WatchSku, deleteHandler: (watchId: string) => void }) {
  function del(): void {
    deleteHandler(watch.watchId);
  }

  return (
    <Row style={{marginBottom: '5px'}}>
      <Col>SKU: {watch.sku}</Col>
      <Col>
        <div style={{ float: 'right', marginTop: '-3px' }}>
          {watch.free ? 'free! ' : ''}
          <Button onClick={del} variant="danger" className="float-right"><FontAwesomeIcon icon={faTrashAlt} /></Button>
        </div>
      </Col>
    </Row>
  );
}
