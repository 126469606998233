import { useRef, useState } from 'react';
import { Watch, WatchCategory, WatchSku } from '../../types';
import Button from 'react-bootstrap/esm/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Tooltip from 'react-bootstrap/esm/Tooltip';

export function WatchForm({ addHandler }: { addHandler: (watch: Watch) => void }) {
  const [type, setType] = useState<Watch['type'] | undefined>(undefined);
  const [category, setCategory] = useState<WatchCategory['category']>('');
  const [sku, setSku] = useState<WatchSku['sku']>('');
  // const [variant, setVariant] = useState<WatchSku['variant']>('');
  const target = useRef(null);

  function add(): void {
    if (type === 'category') {
      addHandler({ type, category } as Watch);  
    }
    if (type === 'sku') {
      addHandler({ type, sku } as Watch);  
    }

    setType(undefined);
    setCategory('');
    setSku('');
  }

  function valid(
    type: Watch['type'] | undefined,
    category: WatchCategory['category'],
    sku: WatchSku['sku'],
  ): boolean {
    const validCategory = type === 'category' && category !== '';
    const validSku = type === 'sku' && sku !== '';
    return validCategory || validSku;
  }

  function typeText(type: Watch['type'] | undefined): string {
    if (type === 'category') {
      return 'Category';
    }
    if (type === 'sku') {
      return 'SKU';
    }
    return 'Select Type';
  }

  return (
    <Container className='w-100'>
      <Row>
        <Col>
          <Dropdown>
            <Dropdown.Toggle>{typeText(type)}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setType('category')}>Category</Dropdown.Item>
              <Dropdown.Item onClick={() => setType('sku')}>SKU</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
        {
          type === 'category'
            ?
            <div>
              <Dropdown>
                  <Dropdown.Toggle>{category || 'Select Category'}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setCategory('Category1')}>Category1</Dropdown.Item>
                  <Dropdown.Item onClick={() => setCategory('Category2')}>Category2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            : null
        }
        {
          type === 'sku'
            ?
            <div>
                <Form.Control type="text" placeholder="Enter SKU" onChange={e => setSku(e.target.value)}/>
                {/* <input value={sku} onChange={e => setSku(e.target.value)} /> */}
            </div>
            : null
          }
        </Col>
        <Col>
          <div style={{ float: 'right', marginTop: '-3px' }} ref={target}>
            <Button onClick={add} variant="success" disabled={!valid(type, category, sku)}>Add</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
