export type Runnable = () => {};

export function getJwt(): string {
  return localStorage.getItem('JWT') || '';
}

export function setJwt(value: string): void {
  localStorage.setItem('JWT', value);
  localStorage.setItem('JWT.time', String(new Date().getTime()));
}

export function getJwtTime(): number{
  return Number(localStorage.getItem('JWT.time'));
}

export function logout(): void {
  localStorage.removeItem('JWT');
  localStorage.removeItem('JWT.time');
  location.replace('/login.html');
}
