import { useEffect, useState } from 'react';
// import pay from './pay.png' 
// import stop from './stop.png' 
import { CancelSubscriptionResponse, GetSubscribeUrlResponse, Jwt, JwtResponse, User, UserResponse, Watch, WatchResponse } from '../../types';
import { Header } from './Header';
import { WatchList } from './WatchList';
import { WatchForm } from './WatchForm';
import { setJwt, getJwt, logout, getJwtTime } from './util';
import jwtDecode from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { config } from './config-dev';
import { ErrorBoundary } from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

// import * as React from 'react';
// import { DdRumReactNavigationTracking } from '@datadog/mobile-react-navigation';

// import { Provider } from '@rollbar/react'; // Provider imports 'rollbar'
// const rollbarConfig = {
//   accessToken: '1c13dcdf9eb14db98a19dc736a9bb6da',
//   environment: 'testenv',
//   captureUncaught: true,
//   captureUnhandledRejections: true,
// };

export function App() {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [watch, setWatch] = useState<Watch[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [inactive, setInactive] = useState(false);
  // const navigationRef = React.useRef(null);

  setInterval(checkInactive, 1000);

  function checkInactive(): void {
    const token = getJwt();
    if (token !== '') {
      const data = jwtDecode<Jwt>(token);
      const { ttl } = data;
      const time = getJwtTime();
      const now = new Date().getTime();
      const left = ttl - (now - time) / 1000;
      // console.log(ttl, time, now, ttl - (now - time), ttl - (now - time) / 1000);
      setInactive(left < 60); // inactive if last 60 seconds 
      if (left < 10) {
        logout();
      }
    }
  }

  function loadingOn(): void {
    setLoading(true);
  }

  function loadingOff<T>(input: T): T {
    setLoading(false);
    return input;
  }

  function jwtFromResponse<T>(input: Partial<JwtResponse> & T): T {
    if (input.jwt !== undefined) {
      setJwt(input.jwt);
    }
    return input;
  } 
  
  function getUserInfo(jwt: string): Promise<UserResponse> {
    loadingOn();
    return fetch(`${config.api}/user?jwt=${encodeURIComponent(jwt)}`)
      .then((res) => res.json())
      .then(loadingOff)
      .then(jwtFromResponse)
      .catch(logout);
  }
  
  function addWatch(jwt: string, watch: Watch): Promise<WatchResponse> {
    loadingOn();
    return fetch(
      `${config.api}/watch?jwt=${encodeURIComponent(jwt)}`,
      { method: 'POST', body: JSON.stringify(watch) }
    )
    .then((res) => res.json())
    .then(loadingOff)
    .then(jwtFromResponse)
    .catch(logout);
  }
  
  function deleteWatch(jwt: string, watchId: string): Promise<WatchResponse> {
    loadingOn();
    return fetch(
      `${config.api}/watch/delete?jwt=${encodeURIComponent(jwt)}&watchId=${encodeURIComponent(watchId)}`,
    )
    .then((res) => res.json())
    .then(loadingOff)
    .then(jwtFromResponse)
    .catch(logout);
  }

  function getSubscribeUrl(jwt: string): Promise<GetSubscribeUrlResponse> {
    return fetch(
      `${config.api}/paypal/subscribe-url?jwt=${encodeURIComponent(jwt)}`,
    )
    .then((res) => res.json())
    .then(jwtFromResponse)
    .catch(logout);
  }

  function cancelSubscription(jwt: string): Promise<CancelSubscriptionResponse> {
    return fetch(
      `${config.api}/paypal/unsubscribe?jwt=${encodeURIComponent(jwt)}`,
    )
    .then((res) => res.json())
    .then(jwtFromResponse)
    .catch(logout);
  }

  useEffect(() => {
    refresh().catch(console.error);
  }, [])

  async function refresh(): Promise<void> {
    const userInfo = await getUserInfo(getJwt());
    setUser(userInfo.user);
    setWatch(userInfo.watch);
    checkInactive();
  }

  async function addHandler(watch: Watch): Promise<void> {
    const response = await addWatch(getJwt(), watch);
    setWatch(response.watch);
  }

  async function deleteHandler(watchId: string): Promise<void> {
    const response = await deleteWatch(getJwt(), watchId);
    setWatch(response.watch);
  }

  async function subscribe(): Promise<void> {
    const result = await getSubscribeUrl(getJwt());
    location.assign(result.url);
  }

  async function unsubscribe(): Promise<void> {
    const result = await cancelSubscription(getJwt());
    setUser(result.user);
  }

  return (
    <ErrorBoundary onError={(error) => {
      datadogRum.addError(error);
      console.log(error);
    }}>
      <Modal
        show={inactive}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Inactivity detected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          TODO.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={refresh}>Stay in app</Button>
          <Button variant="secondary" onClick={logout}>Logout</Button>
        </Modal.Footer>
      </Modal>
      { user
        ?
        <div style={{ marginTop: '10px' }}>
          <Header user={user} subscribe={() => subscribe()} unsubscribe={() => unsubscribe()} />
          <hr />
        { loading ? <div id="block"/> : null }
        {watch ? <WatchList watch={watch} deleteHandler={e => deleteHandler(e)}/> : null}
        <hr />
        <WatchForm addHandler={e => addHandler(e)} />
        </div>
        : null
      }
        <div id="footer">
          <a href="terms.html">T&amp;C</a>
          <a href="email:company@softensify.com">Contact Us</a>
          <span>(c) 2023 Copyright Softensify Pty Ltd</span>
      </div>
    </ErrorBoundary>
  );
}
