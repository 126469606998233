import Button from 'react-bootstrap/esm/Button';
import { User, toPaidUntil } from './types';
import { Runnable, logout } from './util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faSignOut } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

export function Header({ user, subscribe, unsubscribe }: { user: User, subscribe: Runnable, unsubscribe: Runnable }) {

  return (
    <Container className='w-100'>
      <Row>
        <Col style={{flex: '0 0 80px'}}>
          [Logo]
        </Col>  
        <Col>
          <Container>
            <Row><Col md='auto'><b>Title</b></Col></Row>
            <Row>
              <Col className='nowrap' md='auto'>
                Notifications will be sent to: {user.userId}
              </Col>
              <Col className='nowrap' md='auto'>
                {user.paidOn ? <span>Paid until: {paidOnToDate(user.paidOn)}</span> : <span>Not paid</span>}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col style={{marginTop: '10px'}} md='auto'>
          <div style={{ float: 'right'}}>
            { user.subscriptionId === undefined
              ? <Button onClick={subscribe} variant="primary"><FontAwesomeIcon icon={faDollar} /> Subscribe</Button>
              : <Button onClick={unsubscribe} variant="danger"><FontAwesomeIcon icon={faDollar} /> Unsubscribe</Button>
          }
          {' '}
          <Button onClick={logout} variant="primary"><FontAwesomeIcon icon={faSignOut} /> Logout</Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

function paidOnToDate(paidUntil: string): string {
  return toPaidUntil(paidUntil).toLocaleDateString()
}
