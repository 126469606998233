export const BY_PRODUCT_KEY = 'NjJ71DFyQBMt5ZvyzJqhWOJPFrFZC5S73bEO5LDZ5Xc194HOhVwcNP3xJEmg12cpDBDHs3WorfUtXC59SXO26X1zA5m38cdxC6sT';

export interface User {
  readonly userId: string;
  readonly paidOn?: string;
  readonly subscriptionId?: string;
}

interface WatchCommon {
  readonly watchId: string;
  readonly userId: string;
  readonly created: number;
}

export interface WatchCategory extends WatchCommon {
  readonly type: 'category';
  readonly category: string;
}

export interface WatchSku extends WatchCommon {
  readonly type: 'sku';
  readonly sku: string;
  // readonly variant?: string;
  readonly free: boolean;
}

export type Watch = WatchCategory | WatchSku;

export interface JwtResponse {
  readonly jwt: string;
}

export interface UrlResponse {
  readonly url: string;
}

export interface UserResponse {
  readonly user: User;
  readonly watch: Watch[];
}

export interface WatchResponse {
  readonly watch: Watch[];
}

export interface Jwt {
  readonly userId: string;
  readonly exp: number;
  readonly ttl: number;
}

export interface GetSubscribeUrlResponse {
  readonly url: string;
}

export interface SetSubscriptionResponse {
}

export interface CancelSubscriptionResponse {
  readonly user: User;
}

export interface ProductWatchesResponse {
  readonly users: User[];
  readonly watches: Watch[];
}


export function toPaidUntil(paidOn: string): Date {
  const paidUntil = new Date(paidOn);
  paidUntil.setMonth(paidUntil.getMonth() + 1);
  paidUntil.setDate(paidUntil.getDate() + 1);
  return paidUntil;
}
