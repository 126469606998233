import ListGroup from 'react-bootstrap/esm/ListGroup';
import { Watch } from '../../types';
import { WatchRecord } from './WatchRecord';
import Container from 'react-bootstrap/esm/Container';

export function WatchList({ watch, deleteHandler }: { watch: Watch[], deleteHandler: (watchId: string) => void }) {
  return (
    <Container>
      {
        watch.map((record) => <WatchRecord key={record.watchId} watch={record} deleteHandler={e => deleteHandler(e)} />)
      }
    </Container>
  );
}
